import {
    PermissionCheckerService,
    FeatureCheckerService,
    LocalizationService,
    MessageService,
    AbpMultiTenancyService,
    NotifyService,
    SettingService,
} from 'abp-ng2-module';
import { Component, Injector, OnDestroy } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppUrlService } from '@shared/common/nav/app-url.service';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AppUiCustomizationService } from '@shared/common/ui/app-ui-customization.service';
import { PrimengTableHelper } from 'shared/helpers/PrimengTableHelper';
import { ReportingServiceProxy, UiCustomizationSettingsDto } from '@shared/service-proxies/service-proxies';
import '@shared/service-proxies/tenant-login-info-dto-extensions';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSpinnerTextService } from '@app/shared/ngx-spinner-text.service';
import { NepaliDatepickerPrivateService } from '@app/shared/common/nepalidatepicker/services/nepali-datepicker-angular-private.service';
import { LocalStorageService } from '@shared/utils/local-storage.service';

interface AbpEventSubscription {
    eventName: string;
    callback: (...args: any[]) => void;
}

@Component({
    template: '',
})
export abstract class AppComponentBase implements OnDestroy {
    emptyguId = '00000000-0000-0000-0000-000000000000';
    localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;
    injector: Injector;

    localization: LocalizationService;
    permission: PermissionCheckerService;
    feature: FeatureCheckerService;
    notify: NotifyService;
    setting: SettingService;
    message: MessageService;
    multiTenancy: AbpMultiTenancyService;
    appSession: AppSessionService;
    primengTableHelper: PrimengTableHelper;
    ui: AppUiCustomizationService;
    appUrlService: AppUrlService;
    spinnerService: NgxSpinnerService;
    eventSubscriptions: AbpEventSubscription[] = [];

    private ngxSpinnerTextService: NgxSpinnerTextService;

    //ag grid
    totalRecords: number = 0;
    currentPage: number = 0;
    pageSize: number = 50;
    pageSizeOptions: number[] = [];
    nepaliDateService: NepaliDatepickerPrivateService;
    appReporting: ReportingServiceProxy;
    getfrommiti: string;
    gettomiti: string;
    today: string;
    isPrint = false;
    localStorageService: LocalStorageService;

    constructor(injector: Injector) {
        this.injector = injector;
        this.localization = injector.get(LocalizationService);
        this.permission = injector.get(PermissionCheckerService);
        this.feature = injector.get(FeatureCheckerService);
        this.notify = injector.get(NotifyService);
        this.setting = injector.get(SettingService);
        this.message = injector.get(MessageService);
        this.multiTenancy = injector.get(AbpMultiTenancyService);
        this.appSession = injector.get(AppSessionService);
        this.ui = injector.get(AppUiCustomizationService);
        this.appUrlService = injector.get(AppUrlService);
        this.primengTableHelper = new PrimengTableHelper();
        this.spinnerService = injector.get(NgxSpinnerService);
        this.ngxSpinnerTextService = injector.get(NgxSpinnerTextService);
        this.nepaliDateService = injector.get(NepaliDatepickerPrivateService);
        this.appReporting = injector.get(ReportingServiceProxy);

        this.localStorageService = injector.get(LocalStorageService);
    }

    get currentTheme(): UiCustomizationSettingsDto {
        return this.appSession.theme;
    }

    get appLogoSkin(): string {
        if (this.currentTheme.isTopMenuUsed || this.currentTheme.isTabMenuUsed) {
            return this.currentTheme.baseSettings.layout.darkMode ? 'light' : 'dark';
        }

        return this.currentTheme.baseSettings.menu.asideSkin;
    }

    get containerClass(): string {
        if (this.layoutType === 'fluid') {
            return 'app-container container-fluid';
        } else if (this.layoutType === 'fixed' || this.layoutType === 'fluid-xxl') {
            return 'app-container container-xxl';
        }

        return 'app-container container';
    }

    get footerContainerClass(): string {
        if (this.appSession.theme.baseSettings.footer.footerWidthType === 'fluid') {
            return 'app-footer-container container-fluid';
        } else if (
            this.appSession.theme.baseSettings.footer.footerWidthType === 'fixed' ||
            this.appSession.theme.baseSettings.layout.layoutType === 'fluid-xxl'
        ) {
            return 'app-footer-container container-xxl';
        }
        return 'app-container container';
    }

    get layoutType(): string {
        return this.appSession.theme.baseSettings.layout.layoutType;
    }

    ngOnDestroy(): void {
        this.unSubscribeAllEvents();
    }

    flattenDeep(array) {
        return array.reduce(
            (acc, val) => (Array.isArray(val) ? acc.concat(this.flattenDeep(val)) : acc.concat(val)),
            [],
        );
    }

    l(key: string, ...args: any[]): string {
        args.unshift(key);
        args.unshift(this.localizationSourceName);
        return this.ls.apply(this, args);
    }

    ls(sourcename: string, key: string, ...args: any[]): string {
        let localizedText = this.localization.localize(key, sourcename);

        if (!localizedText) {
            localizedText = key;
        }

        if (!args || !args.length) {
            return localizedText;
        }

        args.unshift(localizedText);
        return abp.utils.formatString.apply(this, this.flattenDeep(args));
    }

    isGranted(permissionName: string): boolean {
        return this.permission.isGranted(permissionName);
    }

    isGrantedAny(...permissions: string[]): boolean {
        if (!permissions) {
            return false;
        }

        for (const permission of permissions) {
            if (this.isGranted(permission)) {
                return true;
            }
        }

        return false;
    }

    s(key: string): string {
        return abp.setting.get(key);
    }

    appRootUrl(): string {
        return this.appUrlService.appRootUrl;
    }

    showMainSpinner(text?: string): void {
        this.ngxSpinnerTextService.setText(text);
        this.spinnerService.show();
    }

    hideMainSpinner(text?: string): void {
        this.spinnerService.hide();
    }

    getSetting() {
        this.today = this.nepaliDateService.getCurrentNepaliDate();

        this.localStorageService.getItem(AppConsts.suktasStorage.financialYear, (err, data) => {
            if (!data) this.getFinancialYear();
            this.getfrommiti = data.fromMiti;
            this.gettomiti = data.toMiti;
        });
    }

    getFinancialYear() {
        this.appReporting.getFinancialYears().subscribe((result) => {
            this.localStorageService.setItem(AppConsts.suktasStorage.financialYear, result);

            this.getfrommiti = result.fromMiti;
            this.gettomiti = result.toMiti;
        });
    }

    protected calculatePageSizeOptions(): void {
        const baseSizes = [
            50, 100, 250, 500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 10000, 20000, 50000, 100000,
            200000, 500000, 1000000,
        ];
        this.pageSizeOptions = baseSizes.filter((size) => size <= this.totalRecords);
        if (!this.pageSizeOptions.includes(this.totalRecords)) {
            let index = this.pageSizeOptions.length;
            while (index > 0 && this.pageSizeOptions[index - 1] > this.totalRecords) {
                this.pageSizeOptions.push(this.pageSizeOptions[index - 1] * 2);
            }
        }

        this.pageSizeOptions.push(this.totalRecords);
    }

    protected subscribeToEvent(eventName: string, callback: (...args: any[]) => void): void {
        abp.event.on(eventName, callback);
        this.eventSubscriptions.push({
            eventName,
            callback,
        });
    }

    private unSubscribeAllEvents() {
        this.eventSubscriptions.forEach((s) => abp.event.off(s.eventName, s.callback));
        this.eventSubscriptions = [];
    }
}
