import { Component } from '@angular/core';

@Component({
    standalone: false,
    selector: 'kt-scroll-top',
    templateUrl: './scroll-top.component.html',
})
export class ScrollTopComponent {

}
