import { AbpHttpInterceptor, RefreshTokenService, AbpHttpConfigurationService } from 'abp-ng2-module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';
import { ZeroRefreshTokenService } from '@account/auth/zero-refresh-token.service';
import { ZeroTemplateHttpConfigurationService } from './zero-template-http-configuration.service';

@NgModule({
    providers: [

        ApiServiceProxies.ChamberStatusesServiceProxy,
    ApiServiceProxies.FirmTypesServiceProxy,
    ApiServiceProxies.EmployeePunchsServiceProxy,
    ApiServiceProxies.EmployeeShiftsServiceProxy,

    ApiServiceProxies.BlogsServiceProxy,
    ApiServiceProxies.PdcCleranceReportServiceProxy,
    ApiServiceProxies.PdcPayableReportServiceProxy,
    ApiServiceProxies.WorkUnitsServiceProxy,
    ApiServiceProxies.OnlineMeetingServiceProxy,
    ApiServiceProxies.GalleryCategoriesServiceProxy,
    ApiServiceProxies.TestimonialsServiceProxy,
    ApiServiceProxies.GalleryServiceProxy,
    ApiServiceProxies.CareersServiceProxy,
    ApiServiceProxies.BannerCategoriesServiceProxy,
    ApiServiceProxies.BannersServiceProxy,
    ApiServiceProxies.BlogCategoriesServiceProxy,
    ApiServiceProxies.CashFlowReportServiceProxy,
    ApiServiceProxies.PdcReceivableReportServiceProxy,
    ApiServiceProxies.BalanceSheetReportServiceProxy,
    ApiServiceProxies.CalenderEventsServiceProxy,
    ApiServiceProxies.JournalMastersServiceProxy,
    ApiServiceProxies.EmployeeOfficeTimesServiceProxy,
    ApiServiceProxies.EmployeeOverTimesServiceProxy,
    ApiServiceProxies.ProfitLossReportServiceProxy,
    ApiServiceProxies.EmployeeBonusDeductionsServiceProxy,
    ApiServiceProxies.ReportingServiceProxy,
    ApiServiceProxies.AccountGroupReportServiceProxy,
    ApiServiceProxies.AccountLedgerReportServiceProxy,
    ApiServiceProxies.DayBookReportServiceProxy,
    ApiServiceProxies.CashBookReportServiceProxy,
    ApiServiceProxies.OutstandingReportServiceProxy,
    ApiServiceProxies.DailyCollectionReportServiceProxy,
    ApiServiceProxies.TrailBalanceReportServiceProxy,
    ApiServiceProxies.MergeLedgerServiceProxy,

    // date
    ApiServiceProxies.DateSelectsServiceProxy,
    ApiServiceProxies.EmployeeLoanAndAdvancesServiceProxy,
    ApiServiceProxies.SalaryVoucherMastersServiceProxy,
    ApiServiceProxies.AccountWiseLedgerReportServiceProxy,
    ApiServiceProxies.EmployeeLoanAndAdvancesServiceProxy,
    ApiServiceProxies.EmployeeSalariesServiceProxy,
    ApiServiceProxies.EmployeeLopDeductionsServiceProxy,
    ApiServiceProxies.LedgerSummaryReportServiceProxy,
    // transactions
    ApiServiceProxies.BankReconciliationsServiceProxy,
    ApiServiceProxies.PdcClearancesServiceProxy,
    ApiServiceProxies.PdcPayablesServiceProxy,
    ApiServiceProxies.PdcReceivablesServiceProxy,
    ApiServiceProxies.PayHeadsServiceProxy,
    ApiServiceProxies.AttendanceMastersServiceProxy,
    ApiServiceProxies.VoucherTypesServiceProxy,
    ApiServiceProxies.ContraMastersServiceProxy,
    ApiServiceProxies.AccountGroupsServiceProxy,
    ApiServiceProxies.BranchsServiceProxy,
    ApiServiceProxies.BranchsServiceProxy,
    ApiServiceProxies.UserServiceProxy,
    ApiServiceProxies.ReceiptMastersServiceProxy,
    ApiServiceProxies.PaymentMastersServiceProxy,
    ApiServiceProxies.FinancialYearsServiceProxy,
    ApiServiceProxies.EmployeesServiceProxy,
    ApiServiceProxies.AccountLedgersServiceProxy,
    ApiServiceProxies.AccountGroupsServiceProxy,
    ApiServiceProxies.BranchsServiceProxy,
    ApiServiceProxies.DepartmentsServiceProxy,
    ApiServiceProxies.DesignationsServiceProxy,


    //Cms
    ApiServiceProxies.CmsFacilitiesServiceProxy,
    ApiServiceProxies.CmsDownloadsServiceProxy,
    ApiServiceProxies.CmsFrontendServiceProxy,
    ApiServiceProxies.CmsPagesServiceProxy,
    ApiServiceProxies.CmsEventsServiceProxy,
    ApiServiceProxies.CmsCourseServiceProxy,
    ApiServiceProxies.CmsVideosServiceProxy,
    ApiServiceProxies.CmsVideoCategoriesServiceProxy,
    ApiServiceProxies.ArticlesServiceProxy,
    ApiServiceProxies.NoticesServiceProxy,

    //fee
    ApiServiceProxies.FeeHeadsServiceProxy,
    ApiServiceProxies.DocumentNumberingsServiceProxy,
    ApiServiceProxies.BackGroundImageUploadServiceProxy,
    ApiServiceProxies.TeamMembersServiceProxy,


    //chambe
    ApiServiceProxies.FeeStructuresServiceProxy,
    ApiServiceProxies.FirmsServiceProxy,
    ApiServiceProxies.FeeCollectionsServiceProxy,
    ApiServiceProxies.FirmCategoriesServiceProxy,
    ApiServiceProxies.FirmRenewServiceProxy,
    ApiServiceProxies.RecommendationServiceProxy,
    ApiServiceProxies.CapitalEvaluationsServiceProxy,
    ApiServiceProxies.NoneFirmsServiceProxy,
    ApiServiceProxies.NoneFirmCollectionServiceProxy,
    ApiServiceProxies.FeeCollectionReportServiceProxy,
    ApiServiceProxies.FirmReportServiceProxy,
    ApiServiceProxies.FeeDayBookReportServiceProxy,
    ApiServiceProxies.FirmRenewReportServiceProxy,
    ApiServiceProxies.FirmMembershipReportServiceProxy,
    ApiServiceProxies.RenewFirmReportServiceProxy,
    ApiServiceProxies.NonFirmReportServiceProxy,
    ApiServiceProxies.FirmWiseFeeCollectionReportServiceProxy,




        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.ChatServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.FriendshipServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.PaymentServiceProxy,
        ApiServiceProxies.DemoUiComponentsServiceProxy,
        ApiServiceProxies.InvoiceServiceProxy,
        ApiServiceProxies.SubscriptionServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        ApiServiceProxies.PayPalPaymentServiceProxy,
        ApiServiceProxies.StripePaymentServiceProxy,
        ApiServiceProxies.DashboardCustomizationServiceProxy,
        ApiServiceProxies.WebhookEventServiceProxy,
        ApiServiceProxies.WebhookSubscriptionServiceProxy,
        ApiServiceProxies.WebhookSendAttemptServiceProxy,
        ApiServiceProxies.UserDelegationServiceProxy,
        ApiServiceProxies.DynamicPropertyServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyDefinitionServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyServiceProxy,
        ApiServiceProxies.DynamicPropertyValueServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyValueServiceProxy,
        ApiServiceProxies.TwitterServiceProxy,
        ApiServiceProxies.EntityChangeServiceProxy,
        { provide: RefreshTokenService, useClass: ZeroRefreshTokenService },
        { provide: AbpHttpConfigurationService, useClass: ZeroTemplateHttpConfigurationService },
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
    ],
})
export class ServiceProxyModule {}
