import { PermissionCheckerService } from 'abp-ng2-module';
import { AppSessionService } from '@shared/common/session/app-session.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';

@Injectable()
export class AppNavigationService {
    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService
    ) { }

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem(
                'Dashboard',
                'Pages.Administration.Host.Dashboard',
                'fa-duotone  fa-house',
                '/app/admin/hostDashboard'
            ),
            new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'fa-duotone  fa-house', '/app/main/dashboard'),
            new AppMenuItem(
                'Saas',
                '',
                'flaticon-users',
                '',
                [],
                [
                    new AppMenuItem('Tenants', 'Pages.Tenants', 'flaticon-list-3', '/app/admin/tenants'),

           
             new AppMenuItem('Editions', 'Pages.Editions', 'flaticon-app', '/app/admin/editions'),
                ]
            ),
           
            // new AppMenuItem(
            //     'DemoUiComponents',
            //     'Pages.DemoUiComponents',
            //     'flaticon-shapes',
            //     '/app/admin/demo-ui-components'
            // ),



            new AppMenuItem(
                'Chamber',
                '',
                'fa-duotone fa-building-columns',
                '',
                [],
                [
                    new AppMenuItem('FirmType', 'Pages.FirmTypes', 'fa-duotone fa-user-plus', '/app/main/chamber/FirmTypes',
                        [
                            '/app/main/chamber/FirmTypes/add',
                            '/app/main/chamber/FirmTypes/edit'
                        ]

                    ),
                    new AppMenuItem('FeeHead', 'Pages.FeeHeads', 'fa-duotone fa-user-plus', '/app/main/chamber/feehead',
                        [
                            '/app/main/chamber/feehead/add',
                            '/app/main/chamber/feehead/edit'
                        ]
                    ),
                    new AppMenuItem('FeeStructure', 'Pages.FeeStructures', 'fa-duotone fa-money-check-dollar-pen', '/app/main/chamber/feestructure',
                        [
                            '/app/main/chamber/feestructure/add',
                            '/app/main/chamber/feestructure/edit'
                        ]
                    ),
                    new AppMenuItem('FirmCategory', 'Pages.FirmCategories', 'fa-duotone fa-cubes-stacked', '/app/main/chamber/firmcategories',
                        [
                            '/app/main/chamber/firmcategories/add',
                            '/app/main/chamber/firmcategories/edit'
                        ]
                    ),
                    new AppMenuItem('Firm', 'Pages.Firms', 'fa-duotone fa-buildings', '/app/main/chamber/firm',
                        [
                            '/app/main/chamber/firm/add',
                            '/app/main/chamber/firm/edit'
                        ]
                    ),
                    new AppMenuItem('Firm InActive', 'Pages.ChamberStatuses', 'fa-duotone fa-buildings', '/app/main/chamber/firmInActive',
                        [
                            '/app/main/chamber/firmInActive/add',
                            '/app/main/chamber/firmInActive/edit'
                        ]
                    ),
                    new AppMenuItem('Non-Firm', 'Pages.NoneFirms', 'fa-duotone fa-building', '/app/main/chamber/noneFirm',
                        [
                            '/app/main/chamber/noneFirm/add',
                            '/app/main/chamber/noneFirm/edit'
                        ]
                    ),
                    new AppMenuItem('FeeCollection', 'Pages.FeeCollections', 'fa-duotone fa-envelope-open-dollar', '/app/main/chamber/feecollection',
                        [
                            '/app/main/chamber/feecollection/add',
                            '/app/main/chamber/feecollection/edit'
                        ]
                    ),
                    new AppMenuItem('Non-FirmFeeCollection', 'Pages.FeeCollections', 'fa-duotone fa-box-dollar', '/app/main/chamber/noneFirmFee',
                        [
                            '/app/main/chamber/noneFirmFee/add',
                            '/app/main/chamber/noneFirmFee/edit'
                        ]
                    ),
                    new AppMenuItem('FirmRenew', 'Pages.FirmRenews', 'fa-duotone fa-arrows-rotate', '/app/main/chamber/firmRenew',
                        [
                            '/app/main/chamber/firmRenew/add',
                            '/app/main/chamber/firmRenew/edit'
                        ]),
                    new AppMenuItem('CapitalEvaluation', 'Pages.CapitalEvaluations', 'fa-duotone fa-sack-dollar', '/app/main/chamber/capitalEvaluation',
                        [
                            '/app/main/chamber/capitalEvaluation/add',
                            '/app/main/chamber/capitalEvaluation/edit'
                        ]
                    ),
                    new AppMenuItem('Recommendation', 'Pages.CapitalEvaluations', 'fa-duotone fa-thumbs-up', '/app/main/chamber/recomendation',
                        [
                            '/app/main/chamber/recomendation/add',
                            '/app/main/chamber/recomendation/edit'
                        ]
                    ),


                ]
            ),
           

            new AppMenuItem(
                'Accounting',
                'Pages.Accounting',
                'fa-duotone  fa-file-user',
                '',
                [],
                [
                    new AppMenuItem(
                        'AccountGroups',
                        'Pages.AccountGroups',
                        'fa-duotone fa-users',
                        '/app/main/accounting/accountGroups',
                        [
                            '/app/main/accounting/accountGroups/add',
                            '/app/main/accounting/accountGroups/edit'
                        ]
                    ),
                    new AppMenuItem(
                        'AccountLedgers',
                        'Pages.AccountLedgers',
                        'fa-duotone fa-receipt',
                        '/app/main/accounting/accountLedgers',
                        [
                            '/app/main/accounting/accountLedgers/add',
                            '/app/main/accounting/accountLedgers/edit'
                        ]
                    ),
                    new AppMenuItem(
                        'FinancialYears',
                        'Pages.FinancialYears',
                        'fa-duotone fa-calendar-days',
                        '/app/main/accounting/financialYears',
                        [
                            '/app/main/accounting/financialYears/add',
                            '/app/main/accounting/financialYears/edit'
                        ]
                    ),
                    new AppMenuItem(
                        'VoucherType',
                        'Pages.VoucherTypes',
                        'fa-duotone fa-receipt',
                        '/app/main/generalSetting/voucherTypes',
                        [
                            '/app/main/generalSetting/voucherTypes/add',
                            '/app/main/generalSetting/voucherTypes/edit'
                        ]
                    ),
                    new AppMenuItem(
                        'MergeLedger',
                        'Pages.VoucherTypes',
                        'fa-duotone fa-file-circle-plus',
                        '/app/main/accounting/mergeLedger'
                    ),
                    new AppMenuItem(
                        'DocumentNumering',
                        'Pages.VoucherTypes',
                        'fa-duotone fa-input-numeric',
                        '/app/main/generalSetting/documentNumering',
                        [
                            '/app/main/generalSetting/documentNumering/add',
                            '/app/main/generalSetting/documentNumering/edit'
                        ]
                    ),


                ]
            ),
            new AppMenuItem(
                'Transaction',
                '',
                'fa-duotone  fa-tally',
                '',
                [],
                [
                    new AppMenuItem(
                        'ContraMasters',
                        '',
                        'fa-duotone fa-building-columns',
                        '/app/main/transaction/contraMasters',
                        [
                            '/app/main/transaction/contraMasters/add',
                            '/app/main/transaction/contraMasters/edit'
                        ]
                    ),
                    new AppMenuItem(
                        'PaymentMasters',
                        'Pages.PaymentMasters',
                        'fa-duotone fa-sack-dollar',
                        '/app/main/transaction/paymentMasters',
                        [
                            '/app/main/transaction/paymentMasters/add',
                            '/app/main/transaction/paymentMasters/edit'
                        ]
                    ),
                    new AppMenuItem(
                        'ReceiptMasters',
                        'Pages.ReceiptMasters',
                        'fa-duotone fa-receipt',
                        '/app/main/transaction/receiptMaster',
                        [
                            '/app/main/transaction/receiptMaster/add',
                            '/app/main/transaction/receiptMaster/edit'
                        ]
                    ),
                    new AppMenuItem(
                        'JournalMasters',
                        'Pages.JournalMasters',
                        'fa-duotone fa-book-open',
                        '/app/main/transaction/journalMasters',
                        [
                            '/app/main/transaction/journalMasters/add',
                            '/app/main/transaction/journalMasters/edit'
                        ]
                    ),
                    // new AppMenuItem(
                    //     'BankReconciliation',
                    //     'Pages.BankReconciliations',
                    //     'fa-duotone fa-piggy-bank',
                    //     '/app/main/transaction/bankReconciliation'
                    // ),
                    // new AppMenuItem('LoanMaster', 'Pages.LoanMaster', 'pi pi-list', '/app/main/transaction/loanMaster'),
                    // new AppMenuItem(
                    //     'PdcPayable',
                    //     'Pages.PdcPayables',
                    //     'fa-duotone fa-hand-holding-dollar',
                    //     '/app/main/transaction/PdcPayable'
                    // ),
                    // new AppMenuItem(
                    //     'PdcReceivable',
                    //     'Pages.PdcReceivables',
                    //     'fa-duotone fa-hands-holding-dollar',
                    //     '/app/main/transaction/PdcReceivable'
                    // ),
                    // new AppMenuItem(
                    //     'PdcClearance',
                    //     'Pages.PdcClearances',
                    //     'fa-duotone fa-circle-check',
                    //     '/app/main/transaction/PdcClearance'
                    // ),
                ]
            ),

            new AppMenuItem(
                'FinancialReports',
                '',
                'fa-duotone fa-chart-simple',
                '',
                [],
                [
                    new AppMenuItem(
                        'TrailBalance',
                        '',
                        'fa-duotone fa-building-columns',
                        '/app/main/financial-report/trailbalance'
                    ),
                    new AppMenuItem(
                        'Profit/Loss',
                        '',
                        'fa-duotone fa-building-columns',
                        '/app/main/financial-report/profitloss'
                    ),
                    new AppMenuItem(
                        'BalanceSheet',
                        '',
                        'fa-duotone fa-building-columns',
                        '/app/main/financial-report/balancesheet'
                    ),
                ]),

            new AppMenuItem(
                'Reports',
                '',
                'fa-duotone  fa-file-chart-pie',
                '',
                [],
                [
                    new AppMenuItem('FirmReport', 'Pages.CapitalEvaluations', 'fa-duotone fa-chart-simple', '/app/main/chamber/firmReport'),
                    new AppMenuItem('FirmMemberReport', 'Pages.CapitalEvaluations', 'fa-duotone fa-chart-user', '/app/main/chamber/firmMembers'),
                    new AppMenuItem(
                        'FeeCollectionReport',
                        'Pages.CapitalEvaluations',
                        'fa-duotone fa-chart-scatter-bubble',
                        '/app/main/chamber/feeCollectionReport'
                    ),
                    new AppMenuItem('FirmWiseFeeCollection', 'Pages.CapitalEvaluations', 'fa-duotone fa-envelope-open-dollar', '/app/main/chamber/firmWiseFeeCollectionReport'),
                    new AppMenuItem('FeeDayBookReport', 'Pages.CapitalEvaluations', 'fa-duotone fa-chart-line-up', '/app/main/chamber/feeDaybookReport'),
                    new AppMenuItem('FeeSummaryReport', 'Pages.CapitalEvaluations', 'fa-duotone fa-chart-line-up', '/app/main/chamber/feeSummaryReport'),
                    new AppMenuItem('FirmRenewReport', 'Pages.CapitalEvaluations', 'fa-duotone fa-chart-line', '/app/main/chamber/firmRenewReport'),
                    new AppMenuItem('RenewReport', 'Pages.CapitalEvaluations', 'fa-duotone fa-chart-column', '/app/main/chamber/renewFirmReport'),
                    new AppMenuItem('Non-FirmReport', 'Pages.NonFirmReport', 'fa-duotone fa-chart-gantt', '/app/main/chamber/nonFirmReport'),

                    new AppMenuItem(
                        'AccountReport',
                        '',
                        'fa-duotone fa-building-columns',
                        '/app/main/reports/accountledger'
                    ),
                ]),

                new AppMenuItem(
                    'Administration',
                    '',
                    'fa-duotone  fa-screwdriver-wrench',
                    '',
                    [],
                    [
    
                        new AppMenuItem(
                            'OrganizationUnits',
                            'Pages.Administration.OrganizationUnits',
                            'fa-duotone fa-house-blank',
                            '/app/admin/organization-units'
                        ),
                        new AppMenuItem('Roles', 'Pages.Administration.Roles', 'fa-duotone fa-person', '/app/admin/roles'),
                        new AppMenuItem('Users', 'Pages.Administration.Users', 'fa-duotone fa-users', '/app/admin/users'),
                        new AppMenuItem(
                            'Languages',
                            'Pages.Administration.Languages',
                            'fa-duotone fa-language',
                            '/app/admin/languages',
                            ['/app/admin/languages/{name}/texts']
                        ),
                        new AppMenuItem(
                            'AuditLogs',
                            'Pages.Administration.AuditLogs',
                            'fa-duotone fa-list-check',
                            '/app/admin/auditLogs'
                        ),
                        new AppMenuItem(
                            'Maintenance',
                            'Pages.Administration.Host.Maintenance',
                            'fa-duotone fa-sliders',
                            '/app/admin/maintenance'
                        ),
                        new AppMenuItem(
                            'Subscription',
                            'Pages.Administration.Tenant.SubscriptionManagement',
                            'fa-duotone fa-hand-pointer',
                            '/app/admin/subscription-management'
                        ),
                        new AppMenuItem(
                            'VisualSettings',
                            'Pages.Administration.UiCustomization',
                            'fa-duotone fa-eye',
                            '/app/admin/ui-customization'
                        ),
                        new AppMenuItem(
                            'WebhookSubscriptions',
                            'Pages.Administration.WebhookSubscription',
                            'fa-duotone fa-bell',
                            '/app/admin/webhook-subscriptions'
                        ),
                        new AppMenuItem(
                            'DynamicProperties',
                            'Pages.Administration.DynamicProperties',
                            'fa-duotone fa-star',
                            '/app/admin/dynamic-property'
                        ),
                        new AppMenuItem(
                            'Notifications',
                            '',
                            'fa-duotone fa-alarm-clock',
                            '',
                            [],
                            [
                                new AppMenuItem('Inbox', '', 'fa-duotone fa-envelope', '/app/notifications'),
                                new AppMenuItem(
                                    'MassNotifications',
                                    'Pages.Administration.MassNotification',
                                    'fa-duotone fa-paper-plane',
                                    '/app/admin/mass-notifications'
                                ),
                            ]
                        ),
                        new AppMenuItem(
                            'Settings',
                            'Pages.Administration.Host.Settings',
                            'fa-duotone fa-gear',
                            '/app/admin/hostSettings'
                        ),
                        new AppMenuItem(
                            'Settings',
                            'Pages.Administration.Tenant.Settings',
                            'fa-duotone fa-gear',
                            '/app/admin/tenantSettings'
                        ),
                    ]
                ),
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null) {
                if (subMenuItem.route) {
                    return true;
                }
            } else if (this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                if (!subMenuItem.hasFeatureDependency()) {
                    return true;
                }

                if (subMenuItem.featureDependencySatisfied()) {
                    return true;
                }
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                let isAnyChildItemActive = this.checkChildMenuItemPermission(subMenuItem);
                if (isAnyChildItemActive) {
                    return true;
                }
            }
        }

        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (
            menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' &&
            this._appSessionService.tenant &&
            !this._appSessionService.tenant.edition
        ) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let menu = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach((menuItem) => {
            allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach((subMenu) => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }
}
