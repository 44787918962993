<div
    appBsModal
    #userDelegationModal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="createOrEditModal"
    aria-hidden="true"
    [config]="{ backdrop: 'static' }"
>
    <div class="modal-dialog">
        <div class="modal-content">
            <form *ngIf="active" #userDelegationForm="ngForm" novalidate (ngSubmit)="save()">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>{{ 'DelegateNewUser' | localize }}</span>
                    </h5>
                    <button type="button" class="btn-close" (click)="close()" [attr.aria-label]="l('Close')">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="mb-5">
                        <label class="form-label" for="UsernameOrEmailAddress">{{ 'Username' | localize }}</label>
                        <div class="input-group" >
                            <input
                                name="UsernameOrEmailAddress"
                                class="form-control"
                                type="text"
                                readonly="readonly"
                                disabled="disabled"
                                [value]="selectedUsername"
                            />
                            <button class="btn btn-sm btn-primary blue" (click)="showCommonLookupModal()" type="button"><i class="fa fa-search"></i> {{l("Pick")}}</button>
                            <button class="btn btn-danger btn-icon" type="button" (click)="setUserIdNull()"><i class="fa fa-times"></i></button>
                        </div>
                    </div>
                    <div class="mb-5">
                        <label class="form-label" for="StartTime">{{ 'StartTime' | localize }}</label>
                        <input
                            id="StartTime"
                            type="text"
                            name="StartTime"
                            class="form-control"
                            bsDatepicker
                            datePickerLuxonModifier
                            [(date)]="userDelegation.startTime"
                            [(ngModel)]="userDelegation.startTime"
                            [maxDate]="userDelegation.endTime"
                            [bsConfig]="{ adaptivePosition: true }"
                            autocomplete="new-password"
                            required
                        />
                    </div>
                    <div class="mb-5">
                        <label class="form-label" for="EndTime">{{ 'EndTime' | localize }}</label>
                        <input
                            id="EndTime"
                            type="text"
                            name="EndTime"
                            class="form-control"
                            bsDatepicker
                            datePickerLuxonModifier
                            [(date)]="userDelegation.endTime"
                            [(ngModel)]="userDelegation.endTime"
                            [minDate]="userDelegation.startTime"
                            [bsConfig]="{ adaptivePosition: true }"
                            autocomplete="new-password"
                            required
                        />
                    </div>
                </div>
                <div class="modal-footer">
                    <button
                        [disabled]="saving"
                        type="button"
                        class="btn btn-sm btn-light-primary fw-bold"
                        (click)="close()"
                    >
                        {{ 'Cancel' | localize }}
                    </button>
                    <button
                        type="submit"
                        class="btn btn-sm btn-primary fw-bold"
                        [disabled]="!userDelegation.targetUserId || !userDelegationForm.form.valid"
                        [buttonBusy]="saving"
                        [busyText]="l('SavingWithThreeDot')"
                    >
                        <i class="fa fa-save"></i>
                        <span>{{ 'Save' | localize }}</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
<commonLookupModal #userLookupModal (itemSelected)="userSelected($event)"></commonLookupModal>
